import React, { memo, useEffect } from 'react';
import { FiHeart } from 'react-icons/fi';
import { FaHeart } from 'react-icons/fa';
import clsx from 'clsx';

interface HeartButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  className?: string;
  isActive?: boolean;
  forCard?: boolean;
  onChangeStatus?: (isActive: boolean) => void;
}
/**
 * @name HeartButton
 * @description A button that can be used to add/remove an item from the wishlist
 * @param {boolean} isActive - The initial state of the button
 * @param {string} className - The className of the button
 * @param {function} onChangeStatus - The function that will be called when the button is clicked
 * @returns {JSX.Element} - React Component
 * @example
 * <HeartButton isActive={true} onChangeStatus={(isActive) => console.log(isActive)} />
 * @example
 * <HeartButton isActive={true} className="text-primary-400" />
 * @example
 * <HeartButton isActive={true} className="text-primary-400" onChangeStatus={(isActive) => console.log(isActive)} />
 */

const HeartButton = ({ isActive, forCard = true, className, onChangeStatus, ...props }: HeartButtonProps) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onChangeStatus?.(!isActive);
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <button
      {...props}
      className={clsx(
        'z-50 h-[38px] w-[38px] rounded-full p-2 transition-all duration-200 hover:scale-110 ',
        className,
      )}
      onClick={(e) => handleClick(e)}
    >
      <FiHeart
        className={clsx('h-full w-full cursor-pointer', {
          'animate-heartBeat': isActive,
        })}
        stroke="#fff"
        strokeWidth={1.5}
        fill={clsx({
          '#E51A1A': isActive && forCard,
          'rgba(0,0,0,0.5)': !isActive && forCard,
          '#fff': isActive && !forCard,
          transparent: !isActive && !forCard,
        })}
      />
    </button>
  );
};

export default memo(HeartButton);
