import { useEventListener } from '@react-hooks-library/core';
import { MaybeRef, unRef } from '@react-hooks-library/shared';
import { _document } from '@/utils/_ssr.config';

export default function useCustomScroll(
  target: MaybeRef<Element | null | undefined> = _document?.documentElement,
  callback: (coords: { scrollX: number; scrollY: number }) => void,
) {
  const getPositions = () => {
    const el = unRef(target);
    if (!el) return;

    return {
      x: el.scrollLeft,
      y: el.scrollTop,
    };
  };

  useEventListener(
    target,
    'scroll',
    () => {
      const newScrollValues = getPositions();
      if (!newScrollValues) return;

      const { x, y } = newScrollValues;
      callback({ scrollX: x, scrollY: y });
    },
    {
      capture: false,
      passive: true,
    },
  );
}
