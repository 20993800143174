import Head from 'next/head';
export interface Meta {
  meta?: { [key: string]: null | string };
  properties?: { [key: string]: null | string };
  alternates?: any;
  title?: string;
  description?: string;
  canonical?: string;
}
interface MetaProps {
  lang: string;
  meta: Meta;
}

const Meta = ({ lang, meta }: MetaProps) => {
  const { title, description, alternates, canonical, properties } = meta ?? {};
  const socialTags = meta?.meta;

  return (
    <Head>
      <title>{title}</title>
      <link rel="icon" href="/favicon.ico" sizes="any" />

      {/* DEFAULT IMAGE */}
      <meta name="twitter:image" content="/share.jpg" />
      <meta name="og:image" content="/share.jpg" />

      <meta name="theme-color" content="#212731"></meta>

      {/* Locale */}
      {lang ? <meta name="locale" content={lang} /> : null}

      <meta name="description" content={description} />

      {/* Twitter Tag - meta */}
      {socialTags
        ? Object.keys(socialTags).map((key: any, index) => {
            return <meta key={key + index} name={key} content={socialTags[key] ?? ''} />;
          })
        : null}

      {/* OpenGraph Tag - properties */}
      {properties
        ? Object.keys(properties).map((key: any, index) => {
            return <meta key={key + index} property={key} content={properties[key] ?? ''} />;
          })
        : null}

      {/* Alternate */}
      {alternates
        ? Object.keys(alternates).map((key: any, index) => {
            return <link key={key + index} rel="alternate" hrefLang={key} href={alternates[key]} />;
          })
        : null}

      {/* Canonical */}
      {canonical ? <link rel="canonical" href={canonical} /> : null}

      {/* Google JSON-LD */}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'WebSite',
            url: canonical,
            name: title,
            about: description,
            potentialAction: {
              '@type': 'SearchAction',
              target: canonical + '/search/?q={search_term_string}',
              'query-input': 'required name=search_term_string',
            },
          }),
        }}
      />
    </Head>
  );
};

export default Meta;
