import DarkModeButton from '@/components/core/dark-mode-button/DarkModeButton';
import React, { memo } from 'react';
import SearchBar from './SearchBar';

import { HiOutlineMenuAlt1 } from 'react-icons/hi';
import useMenu from '@/hook/useMenu';
import FavoriteButton from './FavoriteButton';
import { useRouter } from 'next/router';
import OverlayLoader from '../../overlay/OverlayLoader';
import useFavoriteItem from '@/hook/useFavoriteItem';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import LangDesktop from '@/components/core/lang/Lang.desktop';
import { useLang } from '@/hook/useLang';

const ActionBar = () => {
  const [isSearchLoading, setIsSearchLoading] = React.useState<boolean>(false);

  const { lang, linkLang, setLang } = useLang({});

  const { wishlist } = useFavoriteItem({});
  const { t } = useTranslation();
  const { toggleMenu, menuOpen } = useMenu();

  const router = useRouter();

  const handleSearch = (value: string) => {
    if (!value) return;

    setIsSearchLoading(true);
    router
      .push({
        pathname: '/search',
        query: { q: value },
      })
      .then(() => window.scrollTo(0, 0))
      .catch(() => window.scrollTo(0, 0))
      .finally(() => setIsSearchLoading(false));
  };

  return (
    <div className="inline-flex items-center gap-1 md:gap-2">
      <div className="hidden lg:flex">
        <LangDesktop activeLang={lang} linkLang={linkLang} onChange={setLang} />
      </div>

      {/* <div className="hidden lg:block"> */}
      <div className="h-9 w-9 lg:block lg:h-11 lg:w-11">
        <DarkModeButton />
      </div>

      <SearchBar onSearch={handleSearch} placeholder={t('search').toString()} />

      <FavoriteButton items={wishlist} />

      <button
        className={clsx(
          'flex h-9 w-9 items-center justify-center rounded-full bg-primary-500 p-2 text-white lg:hidden',
          {
            'pointer-events-none': menuOpen,
          },
        )}
        onClick={() => {
          toggleMenu();
        }}
      >
        <HiOutlineMenuAlt1 className="ml-0.5 h-4 w-4 rotate-180 lg:h-5 lg:w-5" />
      </button>
      <OverlayLoader isLoading={isSearchLoading} />
    </div>
  );
};

export default memo(ActionBar);
