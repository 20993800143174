import useMenu from '@/hook/useMenu';
import clsx from 'clsx';
import React, { FC, useEffect } from 'react';
import Header from '../header/Header';

import DynamicLoader from '@/components/core/spinner/DynamicLoader';
import { useLang } from '@/hook/useLang';
import useMainContainer from '@/hook/useMainContainer';
import { Menu } from '@/models/interfaces/pages/root';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import AlertBar from '../alert-bar/AlertBar';

const DynamicMenu = dynamic(() => import('../header/components/MobileMenu'), {
  ssr: false,
  loading: () => <DynamicLoader />,
});

const DynamicFooter = dynamic(() => import('../footer/Footer'), {
  ssr: false,
  loading: () => <DynamicLoader />,
});

const DynamicWelcomeModal = dynamic(() => import('@/components/shared/modal/WelcomeModal'), {
  ssr: false,
});

interface LayoutProps {
  children: React.ReactNode;
  menu?: Menu;
  changeHeaderOnScroll?: boolean;
  preview?: boolean;
}

const Layout: FC<LayoutProps> = ({ children, changeHeaderOnScroll = false, menu, preview }) => {
  const { menuOpen, toggleMenu } = useMenu();

  useLang({
    link: menu?.lang,
  });

  const router = useRouter();
  const { t } = useTranslation();
  const { ref } = useMainContainer();

  const transitionCss = !menuOpen
    ? 'translate-x-0 scale-100 rounded-none rounded-0'
    : '-translate-x-60 rounded-r-[1.8rem] scale-95';

  useEffect(() => {
    window.addEventListener('resize', () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    return () => {
      window.removeEventListener('resize', () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      });
    };
  }, []);

  useEffect(() => {
    if (menuOpen) {
      toggleMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (menuOpen) toggleMenu();
    };

    const currentRef = ref?.current;

    if (!currentRef) return;

    currentRef.addEventListener('mousedown', handleClickOutside);

    if (!menuOpen) {
      currentRef.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      currentRef?.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen]);

  return (
    <div
      className={clsx('bg-gray-first transition-all duration-500 dark:bg-gray-dark')}
      style={{ zIndex: -1, height: 'calc(var(--vh, 1vh) * 100)' }}
    >
      {/* Menu Side bar */}
      <aside
        className={[
          'fixed bottom-0 right-0 top-0 min-h-screen w-full duration-700 ease-in-out',
          !menuOpen ? 'translate-x-56 opacity-0' : 'translate-0 opacity-100',
        ].join(' ')}
        style={{ zIndex: 0 }}
      >
        <div className="flex h-full justify-end">
          <div
            className={clsx(
              'bg-gradient-radial to-gray-first flex w-60 flex-col items-center justify-start overflow-y-auto from-white via-white duration-1000 ease-in scrollbar-hide',
              {
                'opacity-100': menuOpen,
                'opacity-0': !menuOpen,
              },
            )}
          >
            <DynamicMenu menu={menu?.main} />
          </div>
        </div>
      </aside>

      <div
        className={[
          'shadow-full relative z-10 h-full w-full overflow-hidden transition-all duration-700',
          transitionCss,
        ].join(' ')}
        style={{ zIndex: 1 }}
      >
        {/* Container page (children) */}
        <div
          id="main_container_layout"
          className={clsx(
            'bg-primary relative flex h-full min-h-screen flex-col justify-between overflow-y-scroll bg-light transition-all duration-300 dark:bg-dark',
            {
              '!overflow-hidden': menuOpen,
            },
          )}
        >
          <div
            className={clsx('sticky inset-x-0 top-0 z-50', {
              // 'fixed ': changeHeaderOnScroll,
              // sticky: !changeHeaderOnScroll,
            })}
          >
            {preview ? <AlertBar type="warning" text={t('Preview mode')} /> : null}
            <Header changeHeaderOnScroll={changeHeaderOnScroll} menu={menu?.main} />
          </div>
          {/* Container figli (esempio di sezione) */}
          <div className={clsx('flex-grow', {})}>
            <main className="h-full min-h-[300px] w-full">{children}</main>
          </div>

          <div>
            <DynamicFooter footer={menu?.footer} />
          </div>
        </div>
      </div>

      <DynamicWelcomeModal />

      {/* <CookieBanner /> */}
    </div>
  );
};

export default Layout;
