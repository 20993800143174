import React, { useEffect, useState } from 'react';

interface AlertProps {
  type: 'success' | 'error' | 'warning' | 'info';
  text: string;
}

const AlertBar = ({ type, text }: AlertProps) => {
  const [close, setClose] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setClose(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={[
        'sticky top-0 z-[60] flex items-center justify-between overflow-hidden whitespace-nowrap border-l-4 border-l-yellow-400 bg-yellow-100 px-2 py-1 transition-all',
        // close ? 'w-4 px-0 hover:w-full hover:px-2' : 'w-full px-2 2xl:w-fit',
        'w-full px-2 2xl:w-fit',
      ].join(' ')}
    >
      <p className="pl-4 text-xs text-yellow-800">{text}</p>
    </div>
  );
};

export default AlertBar;
