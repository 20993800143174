import React, { memo } from 'react';

import { useLang } from '@/hook/useLang';
import Logo from '@/components/core/logo/Logo';
import LangComp from '@/components/core/lang/Lang.mobile';
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from '../../custom-accordion/CustomAccordion';

import Link from 'next/link';
import { Main } from '@/models/interfaces/pages/root';

interface MobileMenuProps {
  menu?: Main[]; // cambiare tipo in Menu
}

const MobileMenu = ({ menu }: MobileMenuProps) => {
  const { lang, setLang, linkLang } = useLang({});

  return (
    <div id="menu" className="flex flex-col gap-10 py-10">
      <div className="h-20">
        <Logo type={'logomark'} to="/" />
      </div>

      <nav className="w-full">
        {menu?.map((item, index) => {
          if (item?.children?.length == 0) {
            return (
              <Link
                key={item?.title ?? `item-${index}`}
                href={item?.url}
                className="block w-full py-4 text-center text-lg font-medium hover:underline xl:text-xl"
              >
                {item?.title}
              </Link>
            );
          }
          return (
            <Accordion key={item?.title} type="single" collapsible className="w-full">
              <AccordionItem value="item-1" className="w-full">
                <AccordionTrigger className="w-full text-right text-lg xl:text-xl">
                  {item?.title}
                </AccordionTrigger>
                {item?.children.map((item, index) => (
                  <AccordionContent
                    id={item?.title ?? `item-${index}`}
                    key={item?.title}
                    className="text-center"
                    aria-labelledby={item?.title ?? `item-${index}`}
                  >
                    <Link key={item?.title} href={item?.url} className="hover:underline">
                      {item?.title}
                    </Link>
                  </AccordionContent>
                ))}
              </AccordionItem>
            </Accordion>
          );
        })}
      </nav>

      <div className="mx-auto">
        <LangComp activeLang={lang} onChange={setLang} linkLang={linkLang} />
      </div>
    </div>
  );
};

export default memo(MobileMenu);
