import { CustomDropDownMenu } from '@/components/shared/custom-dropdown-menu/CustomDropDownMenu';
import { Language } from '@/hook/useLang';
import { Lang } from '@/models/interfaces/pages/root';
import clsx from 'clsx';
import { memo, useCallback } from 'react';
import flagITA from '@/assets/images/lang/ita.png';
import flagENG from '@/assets/images/lang/eng.png';
import Image, { StaticImageData } from 'next/image';

interface LangProps {
  activeLang: Language;
  linkLang?: Lang | null;
  onChange?: (lang: Language) => void;
}

const flags: Record<Exclude<Language, null>, StaticImageData> = {
  it: flagITA,
  en: flagENG,
};

const LangComp = ({ activeLang, onChange, linkLang }: LangProps) => {
  const TriggerButton = useCallback(
    () => (
      <span className="flex h-9 w-9 items-center justify-center rounded-full bg-primary-500 p-2 text-white transition-all duration-150 active:scale-95 lg:h-11 lg:w-11">
        {activeLang}
      </span>
    ),
    [activeLang],
  );

  if (!linkLang) {
    return null;
  }

  return (
    <CustomDropDownMenu
      Trigger={TriggerButton}
      align="right"
      wrapperPanelClassName={clsx(
        'transition-all duration-300 !w-[72px] max-h-[300px] overflow-hidden overflow-y-auto',
        {
          // '!w-72': !items?.length,
          // '!w-80 lg:!w-96': items?.length,
        },
      )}
      panelClassName="grid grid-cols-1 p-1.5 gap-4"
    >
      <ul className="flex w-full flex-col items-center gap-4 text-sm">
        {Object.keys(linkLang).map((lang, index) => {
          return (
            <li key={lang} className="w-full rounded-md p-1 hover:bg-stone-200">
              <button
                type="button"
                aria-label={`cambia lingua in ${lang}`}
                className={clsx('flex w-full justify-between gap-2', {
                  'font-bold underline': activeLang === lang,
                })}
                onClick={() => onChange?.(lang as Language)}
              >
                <Image
                  src={flags[lang as Exclude<Language, null>]}
                  alt={`bandiera ${lang}`}
                  width={20}
                  height={16}
                  className="rounded-full"
                />
                {lang}
              </button>
            </li>
          );
        })}
      </ul>
    </CustomDropDownMenu>
  );
};

export default memo(LangComp);
