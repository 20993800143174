import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import React, { useState, useEffect, useRef, memo } from 'react';

interface SearchBarProps {
  placeholder?: string;
  showLabel?: boolean;
  className?: string;
  onSearch?: (value: string) => void;
  onChangeExpanded?: (isExpanded: boolean) => void;
}

const SearchBar = ({
  placeholder = 'Cerca',
  showLabel = true,
  className = '',
  onSearch,
  onChangeExpanded,
}: SearchBarProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState('');

  const searchRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLInputElement>(null);

  const onFocusEnter = () => {
    setIsFocused(true);
    onChangeExpanded?.(true);
  };

  const onFocusLeave = () => {
    setIsFocused(false);
    onChangeExpanded?.(false);
  };

  const onSearchClick = () => {
    onSearch?.(value);
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearchClick();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onFocusLeave();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    if (isFocused && searchRef.current) {
      searchRef.current.focus();
    }
  }, [isFocused]);

  return (
    <div
      ref={wrapperRef}
      className={[
        'bg-dark-50 flex h-full w-fit items-center justify-between rounded-full border font-medium text-gray-900 transition-all duration-300 focus:outline dark:bg-gray-dark ',
        isFocused
          ? 'border-slate-150 pl-2 dark:border-slate-500'
          : 'border-white hover:opacity-80 dark:border-dark',
        className,
      ].join(' ')}
    >
      <input
        ref={searchRef}
        id="search-bar"
        type="search"
        name="search-bar"
        placeholder={placeholder}
        className={[
          'font-open-sans dark:placeholder:text-dark-300 placeholder:text-dark-400 bg-transparent text-sm font-medium text-dark outline-none transition-all duration-300 dark:text-white',
          isFocused ? 'w-36 sm:w-[230px]' : 'w-0',
        ].join(' ')}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => onKeyPress(e)}
      />
      <button
        className={
          'flex h-9 w-9 items-center justify-center rounded-full bg-primary-500 p-2 text-white transition-all duration-150 active:scale-95 md:gap-2  lg:h-11 lg:w-11'
        }
        onClick={isFocused ? () => onSearchClick() : () => onFocusEnter()}
      >
        <MagnifyingGlassIcon className="h-[18px] w-[18px] lg:h-5 lg:w-5" />
      </button>
    </div>
  );
};

export default memo(SearchBar);
