//LAYOUTS
export { default as Layout } from '@/components/shared/layouts/Layout';

// CORE
export { default as Spinner } from '@/components/core/spinner/Spinner';
export { default as Button } from '@/components/core/buttons/Button';
export { default as Breadcrumbs } from '@/components/core/breadcrumbs/Breadcrumbs';
export { default as DarkModeButton } from '@/components/core/dark-mode-button/DarkModeButton';
export { default as Logo } from '@/components/core/logo/Logo';
export { default as Menu } from '@/components/shared/header/components/MobileMenu';

// SHARED
export { default as Modal } from '@/components/shared/modal/Modal';
export { default as SearchBar } from '@/components/shared/header/components/SearchBar';
export { default as EventCard } from '@/components/shared/cards/EventCard';

// TEMPLATES

// UTILITY
export { default as Meta } from '@/components/shared/utility/meta/Meta';
