import DarkModeAnimate from './components/DarkModeAnimate';
import { useContext } from 'react';
import { ThemeContext } from '@/components/shared/utility/theme/ThemeContext';

export default function DarkModeButton() {
  const { theme, setTheme } = useContext(ThemeContext);

  return (
    <button
      className="relative flex h-full w-full items-center justify-center rounded-full bg-primary-500 text-white transition-all duration-150 active:scale-95 "
      type="button"
      onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}
    >
      <DarkModeAnimate mode={theme} />
      <span className="absolute -right-2 -top-0.5 rounded-full bg-green-600 px-1 !text-[10px] !text-white">
        eco
      </span>
    </button>
  );
}
