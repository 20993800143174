import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, memo } from 'react';
import { DotLoader } from 'react-spinners';

interface OverlayLoaderProps {
  isLoading: boolean;
  closeModal?: () => void;
}

const OverlayLoader = ({ closeModal, isLoading }: OverlayLoaderProps) => {
  const handleClose = () => {
    closeModal?.();
  };

  return (
    <Transition appear show={isLoading} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-fit transform overflow-hidden rounded-2xl p-6 text-left align-middle transition-all">
                <div>
                  <DotLoader color="#A91314" />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default memo(OverlayLoader);
