import HeartButton from '@/components/core/buttons/HeartButton';
import useFavoriteItem from '@/hook/useFavoriteItem';
import { CardType } from '@/models/types/types';
import React, { memo, useCallback } from 'react';

interface ContainerFavoriteCardButtonProps<T = CardType> {
  item?: Partial<T>;
  forCard?: boolean;
  className?: string;
}
/**
 * @name ContainerFavoriteCardButton
 * @description
 * Componente che gestisce il componente HeartButton per aggiungere o rimuovere un elemento dai preferiti
 * @param {CardType} item - elemento da aggiungere o rimuovere dai preferiti
 * @param {boolean} forCard - indica se il componente è utilizzato all'interno di un componente Card
 * @param {string} className - classe css da applicare al componente
 * @returns {JSX.Element} - componente React
 * @example
 * <ContainerFavoriteCardButton item={item} />
 *
 */

const ContainerFavoriteCardButton = ({
  item,
  forCard = true,
  className,
}: ContainerFavoriteCardButtonProps): JSX.Element => {
  const { isFavorite, toggleFavorite } = useFavoriteItem({ id: item?.id ?? '', item: item });

  return (
    <HeartButton
      isActive={isFavorite}
      onChangeStatus={toggleFavorite}
      forCard={forCard}
      className={className}
    />
  );
};

export default ContainerFavoriteCardButton;
