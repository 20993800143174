import React, { useMemo } from 'react';
import logomark from 'assets/images/logo/logomark.svg';
import Image from 'next/image';
import Modal from '@/components/shared/modal/Modal';
import Logo from '../logo/Logo';

interface SpinnerProps {
  isLoading?: boolean;
  className?: string;
}

// DA RIFARE CON COMPONENTE LOGO SENZA MODAL
const Spinner = ({ isLoading, className }: SpinnerProps) => {
  return useMemo(
    () => (
      <div className={className}>
        <Logo type="logomark" className="animate-spin-slow" />
      </div>
    ),
    [],
  );
};

export default Spinner;
