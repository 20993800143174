import { useWindowSize } from '@react-hooks-library/core';
import React, { useEffect, useRef, memo } from 'react';

const useMainContainer = () => {
  const mainContainer = useRef<HTMLDivElement | null>(null);

  const { width, height } = useWindowSize();

  useEffect(() => {
    if (document) {
      const ref = document.getElementById('main_container_layout') as HTMLDivElement;
      mainContainer.current = ref;
    }
  }, [width, height]);

  return { ref: mainContainer };
};

export default useMainContainer;
