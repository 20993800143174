import React, { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from './useRTK';
import { CardType } from '@/models/types/types';
import { toggleToWishList } from '@/store/slices/wishlistSlice';

interface UseFavoriteItemProps<T = CardType> {
  id?: string;
  item?: T;
}

function useFavoriteItem<T>({ id, item }: UseFavoriteItemProps<T>) {
  const { wishlist } = useAppSelector((state) => state.wishlist);

  const dispatch = useAppDispatch();

  const isFavorite = useMemo(() => {
    return wishlist.some((item) => item?.id?.toString() === id?.toString());
  }, [wishlist, id]);

  const toggleFavorite = () => {
    if (!id || !item) return;

    dispatch(toggleToWishList(item as any));
  };

  return { wishlist, isFavorite, toggleFavorite };
}

export default useFavoriteItem;
