import { Language } from '@/hook/useLang';
import { Lang as LangComp } from '@/models/interfaces/pages/root';
import clsx from 'clsx';
import { memo } from 'react';

interface LangProps {
  activeLang: Language;
  linkLang?: LangComp | null;
  onChange?: (lang: Language) => void;
}

const LangComp = ({ activeLang, onChange, linkLang }: LangProps) => {
  if (!linkLang) {
    return null;
  }

  return (
    <ul className="flex gap-4 text-sm ">
      {Object.keys(linkLang).map((lang, index) => {
        return (
          <li key={lang}>
            <button
              type="button"
              aria-label={`cambia lingua in ${lang}`}
              className={clsx({
                'font-bold underline': activeLang === lang,
              })}
              onClick={() => onChange?.(lang as Language)}
            >
              {lang}
            </button>
          </li>
        );
      })}
    </ul>
  );
};

export default memo(LangComp);
