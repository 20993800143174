import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { openMenu, closeMenu } from '../store/slices/utilitySlice';
import { useAppSelector, useAppDispatch } from './useRTK';

const useMenu = () => {
  const { menuOpen } = useAppSelector((state) => state.utility);

  const { pathname } = useRouter();

  const dispatch = useAppDispatch();

  const toggleMenu = useCallback(
    (state?: boolean) => {
      if (state === undefined) state = !menuOpen;

      state ? dispatch(openMenu()) : dispatch(closeMenu());
    },
    [dispatch, menuOpen],
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024 && menuOpen) {
        toggleMenu(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [menuOpen, toggleMenu]);

  useEffect(() => {
    if (menuOpen) {
      toggleMenu(false);
    }
  }, [pathname]);

  return { menuOpen, toggleMenu };
};

export default useMenu;
