import React, { ButtonHTMLAttributes, FC, memo } from 'react';
import clsx from 'clsx';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary';
  size?: 'sm' | 'md' | 'lg';
  className?: string;
  children: React.ReactNode;
}

const Button: FC<ButtonProps> = ({ variant = 'primary', size = 'md', children, className, ...props }) => {
  return (
    <button
      {...props}
      className={clsx('disabled:bg-dark-200 rounded-full font-sans disabled:cursor-not-allowed', className, {
        'bg-primary-500 text-white enabled:hover:bg-primary-600 enabled:active:bg-primary-700':
          variant === 'primary',
        'border border-primary-500 bg-white text-primary-500 enabled:hover:bg-gray-100 enabled:active:bg-gray-200':
          variant === 'secondary',
        'px-2.5 py-1 text-sm': size === 'sm',
        'px-6 py-1.5 text-base': size === 'md',
        'px-8 py-2 text-lg': size === 'lg',
      })}
    >
      {children}
    </button>
  );
};
export default memo(Button);
