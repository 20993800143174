import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu';
import { ArrowRightIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import Image, { StaticImageData } from 'next/image';
import Link from 'next/link';
import React, { memo } from 'react';
import Logo from '@/components/core/logo/Logo';

export type MenuItem = {
  id: string;
  title: string;
  url: string;
  children: MenuItem[];
};

interface NavigationMenuItemProps {
  item: any;
  image?: StaticImageData;
  onMouseEnter?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}

const NavigationMenuItem = ({ item, image, onMouseEnter }: NavigationMenuItemProps) => {
  const [triggered, setTriggered] = React.useState(false);

  return (
    <NavigationMenuPrimitive.Item
      onMouseEnter={(e) => {
        onMouseEnter?.(e);
        setTriggered(true);
      }}
      onMouseLeave={() => {
        setTriggered(false);
      }}
    >
      <NavigationMenuPrimitive.Trigger
        className={clsx(
          'flex items-center rounded-md px-2.5 py-1.5 pr-1.5 text-sm hover:bg-gray-100 dark:hover:bg-stone-900',
          'text-sm font-medium',
          'text-stone-700 dark:text-gray-100',
          'focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75',
        )}
      >
        {item.title}
        <ChevronDownIcon
          className={clsx(
            'h-6 w-6 rounded-full p-1.5 text-dark transition-all duration-300 dark:text-white',
            {
              'rotate-180': triggered,
            },
          )}
        />
      </NavigationMenuPrimitive.Trigger>

      <NavigationMenuPrimitive.Content
        className={clsx(
          'absolute left-0 top-0 z-50 w-auto rounded-lg',
          'radix-motion-from-start:animate-enter-from-left',
          'radix-motion-from-end:animate-enter-from-right',
          'radix-motion-to-start:animate-exit-to-left',
          'radix-motion-to-end:animate-exit-to-right',
        )}
      >
        <div className="min-w-[18rem] p-3">
          <div className="flex gap-2">
            <div className="relative aspect-[9/16] max-h-[200px] w-full overflow-hidden rounded-md bg-gray-100 p-4 dark:bg-stone-900">
              <Image
                blurDataURL="/placeholder.png"
                placeholder="blur"
                src={image?.src ?? ''}
                alt={item.title}
                fill
                className="object-cover"
              />
              <div className="absolute bottom-2 right-2 z-10 h-8 w-8 ">
                <Logo type={'logomark'} to="/" className="" />
              </div>
            </div>

            <div className=" flex w-full flex-col space-y-1 rounded-md  pl-2 ">
              {item?.children?.map((child: MenuItem, index: number) => {
                return (
                  <div key={`child.id-${index}`} className="w-full rounded-md bg-white dark:bg-stone-800">
                    <Link
                      href={child.url}
                      className={clsx(
                        'group flex items-center gap-2 rounded-md px-2.5 py-1.5 text-sm hover:bg-gray-100 dark:hover:bg-stone-900',
                        'text-sm font-medium',
                        'text-stone-700 dark:text-gray-100',
                        'focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75',
                      )}
                    >
                      {child.title}
                      <ArrowRightIcon
                        className="ml-auto h-3 w-3 opacity-0 group-hover:animate-rubberBand group-hover:opacity-100"
                        strokeWidth={2}
                      />
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </NavigationMenuPrimitive.Content>
    </NavigationMenuPrimitive.Item>
  );
};

export default memo(NavigationMenuItem);
