import clsx from 'clsx';
import React, { FC, memo, useMemo } from 'react';

interface TitleProps extends React.HTMLAttributes<HTMLHeadingElement> {
  heading?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  sizeBar?: 'none' | 'xs' | 'sm' | 'md' | 'lg';
  children?: React.ReactNode;
  className?: string;
}

const Title: FC<TitleProps> = ({ children, sizeBar, className, heading, ...props }) => {
  const Heading = heading || 'h1';

  const size = useMemo(() => {
    switch (sizeBar) {
      case 'lg':
        return 'after:w-2.5 before:ml-2.5';
      case 'md':
        return 'after:w-2 before:ml-2';
      case 'sm':
        return 'after:w-1.5 before:ml-1.5';
      case 'xs':
        return 'after:w-1 before:ml-1';
      default:
        return 'after:w-2.5 before:ml-2.5';
    }
  }, [sizeBar]);

  return (
    <Heading
      className={clsx('relative flex items-center gap-2 text-left', className, {
        'before:content-[""] after:absolute after:left-0 after:top-0 after:h-[103%] after:rounded-full after:bg-primary-500 after:content-[""]':
          sizeBar !== 'none',
        [size]: sizeBar !== 'none',
      })}
      {...props}
    >
      {children}
    </Heading>
  );
};

export default memo(Title);
