import React, { FC } from 'react';

import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import clsx from 'clsx';

interface CustomDropDownMenuProps {
  children:
    | { child: (active: boolean, close: () => void, disabled: boolean) => JSX.Element }[]
    | JSX.Element
    | null;
  otherChildren?: JSX.Element | null;
  Trigger: () => JSX.Element;
  wrapperPanelClassName?: string;
  panelClassName?: string;
  align?: 'left' | 'right';
  panelFooter?: JSX.Element;
}

/**
 * 1) Passare come children un array di oggetti con la seguente struttura:
 * {
 * child: (active: boolean, close: () => void, disabled: boolean) => JSX.Element
 * }
 *
 * 2) Passare come children un elemento JSX
 *
 * Il componente child deve essere un componente che ritorna un elemento JSX
 *
 * Il componente Trigger deve essere un componente che ritorna un elemento JSX
 *
 * Esempio:
 *
 * <CustomDropDownMenu Trigger={Trigger}>
 * {[
 * { child: (active, close, disabled) => <div>Child 1</div> },
 * { child: (active, close, disabled) => <div>Child 2</div> },
 * { child: (active, close, disabled) => <div>Child 3</div> },
 * ]}
 * </CustomDropDownMenu>
 *
 *
 */
export const CustomDropDownMenu: FC<CustomDropDownMenuProps> = ({
  Trigger,
  children,
  wrapperPanelClassName,
  panelClassName,
  align = 'left',
  otherChildren,
  panelFooter,
}) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button>
        <Trigger />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-90"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-90"
      >
        <Menu.Items
          className={clsx(
            'absolute z-20 mt-2 block w-auto  rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-dark',
            wrapperPanelClassName,
            {
              'left-0 origin-top-left': align === 'left',
              'right-0 origin-top-right': align === 'right',
            },
          )}
        >
          <div className={clsx('h-auto w-full', panelClassName)}>
            {Array.isArray(children)
              ? children?.map((item, index) => (
                  <Menu.Item key={'menu-item' + index.toString()}>
                    {({ active, close, disabled }) => (
                      <span key={'menu-item-children' + index.toString()} className="w-full">
                        {item.child(active, close, disabled)}
                      </span>
                    )}
                  </Menu.Item>
                ))
              : children}
          </div>
          {otherChildren}
          {panelFooter}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
