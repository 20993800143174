import { Lang } from '@/models/interfaces/pages/root';
import { setLinkLang } from '@/store/slices/utilitySlice';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from './useRTK';

export type Language = 'en' | 'it' | null;

interface UseLangProps {
  link?: Lang | null;
}

export const useLang = ({ link }: UseLangProps) => {
  const [lang, setLang] = useState<Language>(null);

  const linkLang = useAppSelector((state) => state.utility.lang);
  const dispatch = useAppDispatch();

  const router = useRouter();
  const { i18n } = useTranslation();

  const onChangeLanguage = (lng: Language) => {
    if (lng === lang) return;

    console.log('onChangeLanguage', lng, linkLang);
    i18n.changeLanguage(lng ?? 'it').then(() => {
      setLang(lng);

      if (linkLang) {
        const route = linkLang[lng ?? 'it'] ?? '';
        window.open(route, '_self');
      } else {
        router.push(router.route, router.asPath, { locale: i18n.language });
      }
    });
  };

  useEffect(() => {
    if (i18n) {
      const lang = i18n.language as Language;
      setLang(lang);
    }
  }, []);

  useEffect(() => {
    if (link && JSON.stringify(link) !== JSON.stringify(linkLang)) {
      dispatch(setLinkLang(link));
    }
  }, [link]);

  return { lang, setLang: onChangeLanguage, linkLang };
};
