import React, { memo, useEffect, useRef, useState } from 'react';
import { ReactComponent as ShapeSVG } from '@/assets/images/shape-card.svg';
import { ReactComponent as ShapeSmalleSVG } from '@/assets/images/shape-small-card.svg';
import Image from 'next/image';
import Link from 'next/link';
import ContainerFavoriteCardButton from '../container-favorite-card-button/ContainerFavoriteCardButton';
import { EventCardType } from '@/models/types/types';
import clsx from 'clsx';

interface EventCardProps<T = EventCardType> {
  item: Partial<T>;
  className?: string;
}

const EventCard = ({ className, item }: EventCardProps) => {
  const [isSmall, setIsSmall] = useState<boolean>(true);
  const refContainer = useRef<HTMLAnchorElement>(null);

  const { permalink, title, dates, image } = item || {};

  useEffect(() => {
    if (refContainer?.current) {
      const width = refContainer?.current?.offsetWidth;

      if (width > 300) {
        setIsSmall(false);
      }
    }
  }, [refContainer]);

  return (
    <Link
      ref={refContainer}
      href={permalink ?? '/'}
      className={clsx('group relative block h-full w-full overflow-hidden rounded-md shadow-md', className)}
    >
      <Image
        blurDataURL="/placeholder.png"
        placeholder="blur"
        src={image?.url ?? '/placeholder.png'}
        fill
        alt={'_calabria_terrabuona'}
        className="h-full w-full object-cover"
      />

      <div className="absolute right-2 top-2">
        <ContainerFavoriteCardButton item={item as any} />
      </div>

      <div className="absolute inset-x-0 bottom-0 h-fit overflow-hidden pt-6">
        <div className="relative z-10 p-4 pb-5 pt-2">
          <p className="text-xs text-white sm:text-sm">{dates}</p>
          <h3 className="line-clamp-2 text-white">{title} </h3>
        </div>
        <ShapeSVG className={clsx('absolute inset-0 h-full w-full', isSmall ? 'hidden' : 'block')} />
        <ShapeSmalleSVG className={clsx('absolute inset-0 h-full w-full', isSmall ? 'block' : 'hidden')} />
        <span className="absolute inset-x-0 bottom-0 z-10 h-[6px] w-full bg-primary-500" />
      </div>
    </Link>
  );
};

export default memo(EventCard);
