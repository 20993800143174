import DynamicLoader from '@/components/core/spinner/DynamicLoader';
import useFavoriteItem from '@/hook/useFavoriteItem';
import { CardType } from '@/models/types/types';
import { ArrowRightIcon, TrashIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { memo, useCallback, useState } from 'react';
import { FiHeart } from 'react-icons/fi';
import { CustomDropDownMenu } from '../../custom-dropdown-menu/CustomDropDownMenu';

const DynamicAnimation = dynamic(() => import('@/components/shared/utility/animations/AnimationEmptybox'), {
  ssr: false,
  loading: () => <DynamicLoader />,
});

interface FavoriteButtonProps<T = CardType> {
  items: T[];
}

type FavoriteListItemProps<T = CardType> = {
  item: T;
  active: boolean;
  close: () => void;
  disabled: boolean;
};

const FavoriteButton = ({ items }: FavoriteButtonProps) => {
  const { t } = useTranslation();

  const TriggerButton = useCallback(
    () => (
      <span className="flex h-9 w-9 items-center justify-center rounded-full bg-primary-500 p-2 text-white transition-all duration-150 active:scale-95 lg:h-11 lg:w-11">
        <FiHeart className="h-4 w-4 lg:h-5 lg:w-5" />
        {items?.length ? (
          <span className="absolute  -right-1.5 -top-1.5 flex h-[18px] w-[18px] items-center justify-center rounded-full border border-white bg-primary-400 p-0.5 text-xs font-medium text-white lg:h-5 lg:w-5">
            {items.length}
          </span>
        ) : null}
      </span>
    ),
    [items],
  );

  return (
    <CustomDropDownMenu
      Trigger={TriggerButton}
      align="right"
      wrapperPanelClassName={clsx(
        'transition-all duration-300 max-h-[300px] overflow-hidden overflow-y-auto',
        {
          '!w-72': !items?.length,
          '!w-80 lg:!w-96': items?.length,
        },
      )}
      panelClassName="grid grid-cols-1 p-3 gap-4"
      otherChildren={
        items?.length ? null : (
          <div className="flex min-h-[150px] -translate-y-4 flex-col items-center justify-center">
            <div className=" w-36">
              <DynamicAnimation />
            </div>
            <p className="mt-2 text-sm text-stone-400">{t('Non sono presenti preferiti')}</p>
          </div>
        )
      }
      panelFooter={<FavoriteListFooter />}
    >
      {items?.map((item, index) => ({
        child(active, close, disabled) {
          return FavoriteListItem({ item, active, close, disabled });
        },
      }))}
    </CustomDropDownMenu>
  );
};

const FavoriteListItem = ({ ...props }: FavoriteListItemProps) => {
  const [trashed, setTrashed] = useState(false);

  const { item, active, close, disabled } = props;
  const { id, subtitle, image, title, permalink } = item || {};
  const router = useRouter();

  const { toggleFavorite } = useFavoriteItem({ id: item?.id ?? '', item: item });

  const handleToggleFavorite = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    toggleFavorite();
  };

  return (
    <Link
      key={id}
      href={permalink || '/'}
      className={clsx(
        'group relative flex h-fit w-full items-start justify-between gap-4 rounded-2xl p-2 text-2xl font-light transition-all hover:bg-stone-50 dark:hover:bg-stone-900',
        {
          'bg-stone-100 dark:bg-stone-900': active,
          'cursor-not-allowed opacity-50': disabled,
          'animate-zoomOutRight': trashed,
        },
      )}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          router.push(permalink || '/');
          close();
        }
      }}
    >
      <div className="flex gap-4">
        <div className="relative aspect-[6/5] h-24">
          <Image
            blurDataURL="/placeholder.png"
            placeholder="blur"
            src={image?.url ?? '/placeholder.png'}
            alt={'_calabria_terrabuona'}
            fill
            className="rounded-xl border bg-stone-50 object-cover"
          />
        </div>
        <div className="flex flex-col gap-1 pt-1">
          <h3 className="line-clamp-2 text-sm font-medium">{title}</h3>
          <span className="line-clamp-3 text-xs font-light">{subtitle}</span>
        </div>
      </div>

      <button className="rounded-full p-2 hover:bg-red-50" onClick={(e) => handleToggleFavorite(e)}>
        <TrashIcon className="h-4 w-4 text-red-600" />
      </button>

      <ArrowRightIcon
        strokeWidth={3}
        className="absolute bottom-2 right-3 h-6 w-6 p-1 text-stone-300 opacity-0 transition-all duration-700 group-hover:animate-rubberBand group-hover:opacity-100"
      />
    </Link>
  );
};

const FavoriteListFooter = () => {
  const { t } = useTranslation();

  return (
    <div className="sticky bottom-0 flex justify-end ">
      <div className="relative">
        <Link
          href="/wishlist"
          className={clsx(
            'flex items-center gap-1 overflow-hidden rounded-tl-2xl bg-primary-500 px-3 py-1 text-white',
            'inverted-corner-after',
            'inverted-corner-before',
          )}
        >
          <span className="text-sm font-medium !text-inherit">{t('Vai ai tuoi preferiti!')}</span>
          <ArrowRightIcon strokeWidth={3} className="h-3.5 w-3.5" />
        </Link>
      </div>
    </div>
  );
};

export default memo(FavoriteButton);
