import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu';
import { clsx } from 'clsx';
import React, { memo } from 'react';
import NavigationMenuItem from './NavigationMenuItem';

import imageFiliera from '@/assets/images/navigation-menu/filiera.jpg';
import imageProdotti from '@/assets/images/navigation-menu/prodotti.jpg';
import imageScopri from '@/assets/images/navigation-menu/scopri.jpg';
import { Main } from '@/models/interfaces/pages/root';

const tempImage = [imageProdotti, imageFiliera, imageProdotti, imageScopri];

type NavigationMenuProps = {
  menu?: Main[];
  overZero?: boolean;
};

const NavigationMenu = ({ menu, overZero }: NavigationMenuProps) => {
  const [currentItemOffsetLeft, setCurrentItemOffsetLeft] = React.useState(0);
  const panelRef = React.useRef<HTMLDivElement>(null);

  const onMouseEnter = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const documentClientRight = document.documentElement.getBoundingClientRect().right;
    const panelClientRect: DOMRect = panelRef.current?.getBoundingClientRect() as DOMRect;

    let offsetLeft = e.currentTarget.offsetLeft;

    // if (panelClientRect.left > documentClientRight) {
    //   offsetLeft = offsetLeft - (panelClientRect.left - documentClientRight) - 20;
    // }

    setCurrentItemOffsetLeft(offsetLeft);
  };

  return (
    <NavigationMenuPrimitive.Root className="relative">
      <NavigationMenuPrimitive.List className="flex flex-row space-x-2 rounded-lg px-2 pr-0 ">
        {menu?.map((item, index) => {
          const key = `${item?.title}-${index.toString()}`;

          if (item?.children?.length == 0) {
            return (
              <NavigationMenuPrimitive.Item key={key} asChild>
                <NavigationMenuPrimitive.Link
                  href={item.url}
                  className={clsx(
                    'flex items-center rounded-md px-2.5 py-1.5 text-sm hover:bg-gray-100 dark:hover:bg-stone-900',
                    'text-sm font-medium text-gray-700 dark:text-gray-100',
                  )}
                >
                  {item.title}
                </NavigationMenuPrimitive.Link>
              </NavigationMenuPrimitive.Item>
            );
          }

          return (
            <NavigationMenuItem key={key} image={tempImage[index]} item={item} onMouseEnter={onMouseEnter} />
          );
        })}

        {/* <NavigationMenuPrimitive.Indicator
          className={clsx(
            'z-10',
            'top-[100%] flex h-2 items-end justify-center overflow-hidden',
            'radix-state-visible:animate-fade-in',
            'radix-state-hidden:animate-fade-out',
            'transition-[width_transform] duration-[250ms] ease-[ease]',
          )}
        >
          <div className="relative top-1 h-2 w-2 rotate-45  bg-white dark:bg-gray-800" />
        </NavigationMenuPrimitive.Indicator> */}
      </NavigationMenuPrimitive.List>

      <div
        className={clsx('absolute flex duration-300 ease-out', 'right-0  w-[140%]', {
          'top-[120%]': !overZero,
          'top-[140%]': overZero,
        })}
        style={{
          perspective: '2000px',
          left: `calc(${currentItemOffsetLeft}px - 26%)`,
        }}
        ref={panelRef}
      >
        <NavigationMenuPrimitive.Viewport
          className={clsx(
            'relative mt-2 overflow-hidden rounded-md border border-slate-200 bg-white shadow-lg dark:border-slate-800 dark:bg-dark',
            'w-radix-navigation-menu-viewport',
            'h-radix-navigation-menu-viewport',
            'radix-state-open:animate-scale-in-content',
            'radix-state-closed:animate-scale-out-content',
            'origin-[top_center] transition-[width_height] duration-300 ease-[ease]',
          )}
        />
      </div>
    </NavigationMenuPrimitive.Root>
  );
};

export default memo(NavigationMenu);
