import { ThemeContext } from '@/components/shared/utility/theme/ThemeContext';
import Link from 'next/link';
import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { ReactComponent as Logomark } from 'assets/images/logo/logomark.svg';

import { ReactComponent as StandardLockup } from 'assets/images/logo/standard-lockup.svg';

import { ReactComponent as StandardLockupMobile } from 'assets/images/logo/standard-lockup-mobile.svg';
import clsx from 'clsx';

type LogoType = 'logomark' | 'standardLockup' | 'standardLockupMobile';

export interface LogoProps {
  type: LogoType;
  to?: string;
  target?: '_self' | '_parent' | '_top';
  className?: string;
}

const logo = {
  ['logomark']: Logomark,
  ['standardLockupMobile']: StandardLockupMobile,
  ['standardLockup']: StandardLockup,
};

const Logo = ({ type, to, target = '_self', className }: LogoProps) => {
  // RIVEDERE QUESTA SOLUZIONE
  const [test, setTest] = useState<any>(null);
  const { theme } = useContext(ThemeContext);

  const LogoComponentChoose = useCallback(() => {
    const Logo = logo[type];

    if (to) {
      return (
        <Link href={to} target={target}>
          <Logo
            className={clsx('h-full w-full', className, {
              'text-white': theme === 'dark',
              'text-dark': theme === 'light',
            })}
          />
        </Link>
      );
    }

    return (
      <Logo
        className={clsx('h-full w-full', className, {
          'text-white': theme === 'dark',
          'text-dark': theme === 'light',
        })}
      />
    );
  }, [className, target, theme, to, type]);

  useEffect(() => {
    setTest(LogoComponentChoose());
  }, [theme]);

  return test;
};

export default memo(Logo);
