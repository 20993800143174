import React from 'react';
import { BeatLoader } from 'react-spinners';

const DynamicLoader = () => {
  return (
    <div className="flex h-full w-full items-center justify-center py-8">
      <BeatLoader color={'#A91314'} />
    </div>
  );
};

export default DynamicLoader;
