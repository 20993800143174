import clsx from 'clsx';
import Link from 'next/link';
import { memo, useMemo } from 'react';

interface BreadcrumbsProps {
  breadcrumbs: { [key: string]: string };
  className?: string;
  textColorClass?: string;
}

const Breadcrumbs = ({ breadcrumbs, className, textColorClass }: BreadcrumbsProps) => {
  const vBreadcrumb = useMemo(() => {
    return Object.keys(breadcrumbs);
  }, [breadcrumbs]);

  return (
    <div className="text-dark-500 flex flex-wrap items-center gap-1.5 dark:text-white">
      {vBreadcrumb.map((key) => {
        const isLast = vBreadcrumb.length - 1 === vBreadcrumb.indexOf(key);

        return (
          <Link
            key={key}
            href={breadcrumbs[key]}
            className={clsx(
              'line-clamp-1 w-fit text-sm last:cursor-default last:font-semibold',
              className,
              textColorClass,
            )}
            onClick={(e) => {
              isLast ? e.preventDefault() : null;
            }}
          >
            {key}

            {isLast ? null : <span className={textColorClass}>{' > '}</span>}
          </Link>
        );
      })}
    </div>
  );
};

export default memo(Breadcrumbs);
