import { memo, useState } from 'react';

import clsx from 'clsx';
import Logo from '@/components/core/logo/Logo';
import ActionBar from './components/ActionBar';

import headerJson from '@/data/header.json';
import useMainContainer from '@/hook/useMainContainer';
import useCustomScroll from '@/hook/useCustomScroll';
import { Transition } from '@headlessui/react';
import useBreakPoint from '@/hook/useBreakPoint';

import NavigationMenu from './components/NavigationMenu';
import { Main } from '@/models/interfaces/pages/root';

interface HeaderProps {
  changeHeaderOnScroll?: boolean;
  menu?: Main[];
}

const Header = ({ changeHeaderOnScroll = false, menu }: HeaderProps) => {
  const [overZero, setOverZero] = useState(!changeHeaderOnScroll);

  const { greaterThanLg } = useBreakPoint();

  const { ref } = useMainContainer();

  useCustomScroll(ref, ({ scrollY }) => {
    if (!changeHeaderOnScroll || !greaterThanLg) return;

    if (scrollY > 0) {
      if (scrollY > 65) {
        if (!overZero) setOverZero(true);
      }
    } else {
      if (overZero) setOverZero(false);
    }
  });

  return (
    <header
      id="header"
      className={clsx(
        'shadow-full relative z-[100] h-16 bg-light text-dark transition-all duration-300 dark:bg-dark dark:text-light ',
        {
          'py-2 lg:h-20': overZero,
          'py-2 lg:h-[136px]': !overZero,
        },
      )}
    >
      <div className="container  mx-auto flex h-full items-center justify-between gap-6">
        {/* Logo */}
        <div className="h-full">
          <Logo type={'standardLockup'} to="/" className="hidden lg:block" />
          <Logo type={'standardLockupMobile'} to="/" className=" lg:hidden" />
        </div>

        {/* Action Bar MOBILE */}
        {!greaterThanLg ? (
          <div className="lg:hidden">
            <ActionBar />
          </div>
        ) : null}

        {/* Navigation menu - Action bar */}
        <div
          className={clsx(
            'hidden h-full transition-all duration-300 lg:flex lg:flex-col lg:items-end lg:gap-5',
            {
              // 'lg:justify-center': overZero,
              'lg:justify-end': !overZero,
            },
          )}
        >
          <div
            className={clsx('h-full transition-all duration-300', {
              'max-h-0 overflow-hidden': overZero,
              'max-h-[42px]': !overZero,
            })}
          >
            <Transition
              show={!overZero}
              enter="transition-opacity duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ActionBar />
            </Transition>
          </div>

          <NavigationMenu menu={menu} overZero={overZero} />
        </div>
      </div>
    </header>
  );
};

export default memo(Header);
